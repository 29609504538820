import './App.scss';
import { Text, Image, Button, Modal, useModal, Navbar, Link, Popover} from '@nextui-org/react';
import logo from './Images/logomykyta.png';
import personal from './Images/mykytadeyneha2.jpg';
import personal2 from './Images/mykytadeyneha2.png';
import { useEffect, useState } from 'react';
import folderImg from './Images/folder.png';
import thesis from './Files/thesis.pdf';
import resume from './Files/resume.pdf';

function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const [jobChosen, setJobChosen] = useState(0);
  const [jobVisibility, setJobVisibility] = useState(0);
  const [activeNavItem, setActiveNavItem] = useState('home');
  const dictionary = {
    0: "#home",
    1: "#about",
    2: "#experience",
    3: "#projects"
  };

  const breakpoint = 768;

  const collapseItems = [
    "Home",
    "About",
    "Experience",
    "Projects",
  ];
  
  const changeJobChosen = event => {
    setJobChosen(event.target.value);

    setTimeout(function () {
      console.log("yeet");
      setJobVisibility(event.target.value);
    }.bind(this), 250);
  };

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      
      for (let i = 0; i < sections.length; i++) {
        const currentSection = sections[i];
        const currentSectionTop = currentSection.offsetTop - 300;
        const currentSectionBottom = currentSectionTop + currentSection.offsetHeight;

        if (scrollPosition >= currentSectionTop && scrollPosition < currentSectionBottom) {
          const currentSectionId = currentSection.getAttribute('id');
          setActiveNavItem(`${currentSectionId}`);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="App">
      <Navbar shouldHideOnScroll className='navbar' fluid variant={'sticky'}>
        <Navbar.Brand className='navbarBrand'>
          <Image src={logo}  className="logo"/>
        </Navbar.Brand>
        <Navbar.Content hideIn="sm" variant={'underline-rounded'}>
          <Navbar.Link href="#home" isActive ={activeNavItem === "home" ? true : false}> <Text className='codeNavbarItem'> 01.&nbsp;</Text> Home </Navbar.Link>
          <Navbar.Link href="#about" isActive ={activeNavItem === "about" ? true : false}> <Text className='codeNavbarItem'> 02.&nbsp;</Text> About </Navbar.Link>
          <Navbar.Link href="#experience" isActive ={activeNavItem === "experience" ? true : false}> <Text className='codeNavbarItem'> 03.&nbsp;</Text> Experience </Navbar.Link>
          <Navbar.Link href="#projects" isActive ={activeNavItem === "projects" ? true : false}> <Text className='codeNavbarItem'> 04.&nbsp;</Text> Projects </Navbar.Link>
        </Navbar.Content>
        <Navbar.Toggle showIn={"sm"} aria-label="toggle navigation" />
        <Navbar.Content hideIn={"sm"}>
          <Navbar.Item href="#"> 
            <Button auto bordered as={Link} href='mailto:mykytadeyneha@icloud.com'> Contact Me </Button>
          </Navbar.Item>
        </Navbar.Content>
        <Navbar.Collapse>
          {collapseItems.map((item, index) => (
            <Navbar.CollapseItem key={item}>
              <Link css={{ minWidth: "150px",}} href={dictionary[index]}>
                <Text className='codeNavbarItem'> 0{index + 1}.&nbsp;</Text> {item}
              </Link>
            </Navbar.CollapseItem>
          ))}
        </Navbar.Collapse>
      </Navbar>

      <ul className='socialnetworkList'>
        <li><a href='https://www.linkedin.com/in/mykyta-deyneha/' target="blank"><div className='linkedin'/></a></li>
        <li><a href='https://www.instagram.com/mykytadeyneha/' target="blank"><div className='instagram'/></a></li>
        <li><a href='https://github.com/mykebueno/' target="blank"><div className='github'/></a></li>
        <li className='bar'></li>
      </ul>

      <div className='email'>
        <a className='emailContact' href='mailto:mykytadeyneha@icloud.com'> mykytadeyneha@icloud.com </a>
        <div className='bar'></div>
      </div>

      <div className='content'>
        <main className='mainContent'>
          <section id='home' className='homeSection'>
            <div className='leftHomeSection'>
              <Text className='text1'>Hi, my name is</Text>
              <Text weight={'bold'} className='text2'>Mykyta Deyneha.</Text>
              <Text weight={'medium'} size={'$xl'} className='text3'>Software Engineer</Text>
              <Text className='text4'>I'm a software engineer specialized in building and maintaining software features. My aim is to ensure software reliability through my programming expertise and problem-solving capabilities. I'm currently focused on building efficient new features and resolving bug issues for the dataminer software at <a className='importantText' href='https://skyline.be/' target={'_blank'}>Skyline Communications</a></Text>
              <div className='leftHomePageButtons'>
                <Button bordered as={Link} href={resume} target="_blank"> Curriculum </Button>
                <Button bordered as={Link} href="#about"> Meet me </Button>
              </div>
            </div>
            <div className='rightHomeSection'>
              <div className='profileWrapper'>
                <Image className='profileImage2' width={490} src={personal2}/>
                <Image className='profileImage' width={490} src={personal}/>
              </div>
              { (width <= breakpoint) && 
                <div className='socialAndEmail'>
                  <ul className='socialnetworkList2'>
                    <li><a href='https://www.linkedin.com/in/mykyta-deyneha/' target="blank"><div className='linkedin'/></a></li>
                    <li><a href='https://www.instagram.com/mykytadeyneha/' target="blank"><div className='instagram'/></a></li>
                    <li><a href='https://github.com/mykebueno/' target="blank"><div className='github'/></a></li>
                  </ul>
                  <a className='emailContact' href='mailto:mykytadeyneha@icloud.com'> mykytadeyneha@icloud.com </a>
                </div>
              }
            </div>
          </section>

          <section id='about' className='aboutSection'>
            <Text weight={'bold'} className='title'>{width <= breakpoint ? "About" : "About me"}</Text>
            <div className='aboutWrapper'>
              <div className='leftAboutWrapper'>
                <Text className='text'>Hello there! My name is Mykyta and I am a highly motivated and experienced software engineer. I first got interested in programming back in high school in 2017 when I wanted to create a small software program to help me with my study schedule organization.</Text>
                <Text className='text'>Today, I am an engineer with a huge passion for problem-solving and a strong understanding of various programming languages and technologies which I have acquired during <a className='importantText' href='https://www.fct.unl.pt/en' target={'_blank'}>university</a>, <a className='importantText' href='#projects'>side-projects</a> and <a className='importantText' href='#experience'>professional experience</a>. </Text>
                <Text className='text'>My main focus these days is building new features and fixing issues at <a className='importantText' href='https://skyline.be/' target={'_blank'}>Skyline Communications</a> for the Dataminer Software.</Text>
              </div>
              <div className='rightAboutWrapper'>
              <Text className='text'> Here are a few technologies I've been working with recently: </Text>
              <ul className='skillsList'>
                <li>JavaScript (ES6+)</li>
                <li>C#</li>
                <li>Node.js</li>
                <li>React</li>
                <li>C++</li>
                <li>MongoDB</li>
                <li>Cassandra</li>
                <li>Elasticsearch</li>
              </ul>
              </div>
            </div>
          </section>

          <section id='experience' className='experienceSection'>
            <Text weight={'bold'} className='title'>{width <= breakpoint ? "Work" : "Where I've Worked"}</Text>
            <div className='experienceTable'> 
              <div className='experienceTabList'>
                <input type="radio" id="radio-1" name="tabs" value={0} onClick={changeJobChosen} defaultChecked />
                <label class="tab" for="radio-1">Skyline Communications</label>
                <input type="radio" id="radio-2" name="tabs" value={1} onClick={changeJobChosen}/>
                <label class="tab" for="radio-2">NOVA University</label>
                <input type="radio" id="radio-3" name="tabs" value={2} onClick={changeJobChosen}/>
                <label class="tab" for="radio-3">Skyline Communications</label>
                <span className='glider'></span>
              </div>

              {jobChosen == 0 ? 
                <div className={jobVisibility == 0? 'experienceDescriptor visible' : 'experienceDescriptor'}>
                  <Text className='text jobtitle'>Software Engineer <span className='importantText'>{width <= 550 ? <br/> : "@"} Skyline Communications</span></Text>
                  <Text className='text jobtime'>October 2022 - Present</Text>
                  <lu className='jobexperience'>
                    <li>Architected an advanced management layer for NATS messaging system within an ASP.NET application, enabling sophisticated configuration and real-time monitoring of the NATS server, resulting in a marked increase in system resilience and operational efficiency.</li>
                    <li>Enhanced the codebase for interacting with Elasticsearch and Cassandra databases, boosting synchronous and asynchronous data processes by over 30%. </li>
                    <li>Tackled data processing challenges by applying concurrency patterns using multi-threading, ensuring robust data handling in different environments.</li>
                    <li>Implemented performance and regression tests to optimize responsiveness and safeguard code changes, enhancing the development cycle.</li>
                    <li>Collaborated in a cross-functional Agile Scrum team, consistently meeting sprint goals and significantly enhancing workflow efficiency.</li>
                    <li>Developed comprehensive technical documentation, simplifying the integration of new hires and acting as a dependable resource for all team members.</li>
                    <li>Designed specialized internal tools to refine processes and automate regular tasks, boosting team performance and workflow efficiency.</li>
                  </lu>
                </div> 
              : null}
              
              {jobChosen == 1 ? 
                <div className={jobVisibility == 1? 'experienceDescriptor visible' : 'experienceDescriptor'}>
                  <Text className='text jobtitle'>Teaching Assistant <span className='importantText'>{width <= 550 ? <br/> : "@"} NOVA University</span></Text>
                  <Text className='text jobtime'>September 2021 - January 2022</Text>
                  <lu className='jobexperience'>
                    <li>Teaching practical classes in the course of Microprocessor Programming for the Electrical and Computer Engineering undergraduate program at Nova School of Science & Technology</li>
                    <li>Teaching fundamentals of programming using C language. </li>
                    <li>Corrected and validated students weekly programming tasks and semester's final projects.</li>
                  </lu>
                </div>
              : null}

              {jobChosen == 2 ? 
                <div className={jobVisibility == 2? 'experienceDescriptor visible' : 'experienceDescriptor'}>
                  <Text className='text jobtitle'>System Developer <span className='importantText'>{width <= 550 ? <br/> : "@"} Skyline Communications</span></Text>
                  <Text className='text jobtime'>July 2021 - August 2022</Text>
                  <lu className='jobexperience'>
                    <li>Design and implementation of advanced Roslyn analyzers with C# to check incorrect code implementations in hardware drivers in development using .Net Compiler Platform SDK.</li>
                    <li>Roslyn analyzers inspect and analyse C# code for style, quality, design and other issues.</li>
                    <li>Roslyn analyzers are built into Visual Studio and run manually to check the implemented code.</li>
                  </lu>
                </div>
              : null}
            </div>
          </section>

          <section id='projects' className='projectsSection'>
            <Text weight={'bold'} className='title'>{width <= breakpoint ? "Projects" : "Some Things I've Built"}</Text>
            <ul className='projectsGrid'>

                <li className='project'>
                  <a href={thesis} target="_blank">
                    <div className='projectInner'>
                      <header>
                        <div className='projectTop'>
                          <img className='folder' src={folderImg}/>
                          <div className='redirect'/>
                        </div>
                        <Text weight={'semibold'} className='text projectTitle'>Smart Shopping in Retail using Beacon Technology</Text>
                        <Text className='text projectDescription'> Master Thesis project where I developed a smartphone and a server application to provide location-based information and services inside a retail store using signals from beacons.</Text>
                      </header>
                      <footer>
                        <ul className='projectTechList'>
                          <li>Swift</li>
                          <li>Node</li>
                          <li>React</li>
                          <li>Express</li>
                          <li>MongoDB</li>
                        </ul>
                      </footer>
                    </div>
                  </a>
                </li>

                <Popover css={{p: "$10"}} placement='bottom' disableShadow>
                  <Popover.Trigger>
                  <li className='project'>
                      <div className='projectInner'>
                        <header>
                          <div className='projectTop'>
                            <img className='folder' src={folderImg}/>
                            <div className='redirect'/>
                          </div>
                          <Text weight={'semibold'} className='text projectTitle'>Car Plate Detection</Text>
                          <Text className='text projectDescription'> Detection of car plates numbers/letters using image processing techniques on static images from the front and back of different coloured cars with different paint finishes.</Text>
                        </header>
                        <footer>
                          <ul className='projectTechList'>
                            <li>C#</li>
                            <li>OpenCV</li>
                          </ul>
                        </footer>
                      </div>
                    </li>
                  </Popover.Trigger>
                  <Popover.Content css={{ backgroundColor: '#383e4a' }}>
                    <Text css={{ p: "$10", color: '#ccd6f6'}}>Sorry! But since this is a university project, this is kept private so that students from my univeristy are not able to copy.</Text>
                  </Popover.Content>
                </Popover>

                <li className='project'>
                  <div className='projectInner'>
                    <header>
                      <div className='projectTop'>
                        <img className='folder' src={folderImg}/>
                        <div className='redirect'/>
                      </div>
                      <Text weight={'semibold'} className='text projectTitle'>Come back later!</Text>
                      <Text className='text projectDescription'> New project coming soon...</Text>
                    </header>
                    <footer>
                      <ul className='projectTechList'>
                        <li></li>
                      </ul>
                    </footer>
                  </div>
                </li>
            </ul>

            <Popover css={{p: "$10"}} placement='bottom' disableShadow>
              <Popover.Trigger>
                <Button className='showMore' bordered> Show More </Button>
              </Popover.Trigger>
              <Popover.Content css={{ backgroundColor: '#383e4a' }}>
                <Text css={{ p: "$10", color: '#ccd6f6'}}>More projects coming very soon!</Text>
              </Popover.Content>
            </Popover>
          </section>

          <section id='contact' className='contactSection'>
            <Text weight={'bold'} className='title'>What's next?</Text>
            <Text weight={'bold'} className='importantText'>Get In Touch</Text>
            <Text className='text'>If you have an interesting project, my inbox is always open.<br/> Come say hi or if you have a question, feel free to ask. <br/> I will get back at you as soon as I can!</Text>
            <Button className='sayHi' bordered as={Link} href='mailto:mykytadeyneha@icloud.com'>Say Hi!</Button>
          </section>
        </main>

        <div className='footer'> 
          <Text weight={'normal'} className='footerText'>Designed & Built by <span className='importantText'> Mykyta Deyneha </span> <br/> Inspired by Brittany Chiang</Text>
        </div>
      </div>
    </div>
  );
}

export default App;
