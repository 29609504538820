import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { NextUIProvider, createTheme } from '@nextui-org/react';

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme({
  type: "light", // it could be "light" or "dark"
  theme: {
    colors: {
      // brand colors
      //primaryLight: '$green200',
      //primaryLightHover: '$green300',
      //primaryLightActive: '$green400',
      //primaryLightContrast: '$green600',
      primaryLight: 'var(--lightest-slate)',
      primary: 'var(--green)',
      //primaryBorder: '$green500',
      //primaryBorderHover: '$green600',
      //primarySolidHover: '$green700',
      //primarySolidContrast: '$white',
      //primaryShadow: '$green500',
      text: 'var(--slate)',
      background:'var(--navy)',
      backgroundAlpha:'var(--navy)',
      gradient: 'linear-gradient(112deg, $blue100 -25%, $pink500 -10%, $purple500 80%)',
      link: 'var(--green)',

      // you can also create your own color
      myColor: '#57af9e'
    },
    space: {},
    fonts: {}
  }
})

root.render(
  <NextUIProvider theme={theme}>
    <App />
  </NextUIProvider>
);


reportWebVitals();
